<template>
  <div class="ShowPreview">
    <notification-popup  
    :dialog="notifSuccess"
    :contentMsg="$t('notifWalletDefaultSuccess')"
    :headerMsg="$t('Success')"
    :nameBtnNo="$t('labelNo')"
    :nameBtnYes="$t('BackToMyWallet')"
    disableBtnNo="true"
    typeModal="transaction"
    imgSource="notification/success2.svg"
    :onHandlerYes="buttonOke"/>


    <notification-popup 
    :dialog="notifFailed"
    :contentMsg="$t('notifWalletDefaultFailed')"
    :headerMsg="$t('failed')"
    :nameBtnNo="$t('labelNo')"
    :nameBtnYes="$t('TryAgain')"
    colorIcon = "danger"
    typeModal="transaction"
    Icon="mdi-close-circle"
    disableBtnNo="true"
    :onHandlerYes="buttonOkeFailed"/>

    <div class="component-1">
      <div class="background"></div>
      <div id="spacer"></div>
      <v-container class="pa-0">
        <v-row class="px-3">
          <v-col class="pb-0">
            <h4 class="pl-3">{{$t('showPreview')}}</h4>
          </v-col>
        </v-row>
        <v-row class="px-3">
          <v-col class="py-0" style="border-bottom: 1px solid #F5F5F5!important;">
            <v-list-item class="onHover pl-3" @click="clickSetPriority(1)">
              <template >
                <v-list-item-content>
                  <v-list-item-title>
                  {{$t('default')}}</v-list-item-title>
                </v-list-item-content>

                <v-list-item-action v-if="priority == 1">
                  <v-icon color="grey lighten-1">
                    mdi-check
                  </v-icon>
                </v-list-item-action>
              </template>
            </v-list-item>
          </v-col>
        </v-row>
        <v-row class="px-3">
          <v-col class="py-0" style="border-bottom: 1px solid #F5F5F5!important;">
            <v-list-item class="onHover pl-3" @click="clickSetPriority(0)">
              <template >
                <v-list-item-content>
                  <v-list-item-title>
                  {{$t('never')}}</v-list-item-title>
                </v-list-item-content>

                <v-list-item-action v-if="priority == 0">
                  <v-icon color="grey lighten-1">
                    mdi-check
                  </v-icon>
                </v-list-item-action>
              </template>
            </v-list-item>
          </v-col>
        </v-row>
      </v-container>
    </div>
  </div>
</template>
<script>
import NotificationPopup from "@/components/NotificationPopup.vue";

  export default {
    name: 'ShowPreview',
    
    components: {NotificationPopup,  },
    data() {
      return {
        disabledBtn:"",
        notifSuccess:false,
        notifFailed:false,
        priority:"",
      }
    },
    computed: {
      paymentMethodData() {
        return this.$store.state.customer.getPaymentMethod;
      } 
    },
    methods:{
      clickSetPriority(priority){
        const payload = {
          customer_sof_id: this.paymentMethodData.customer_sof_id,
          priority: priority
        };

        this.$store
          .dispatch("customer/setPrimary", payload)
          .then((response) => {
            if (response.status == true) {
              this.notifSuccess = true
            }else{
              this.notifFailed = true
            }

          })
          .catch((err) => {
            console.log(err);
          });
      },
      // clickNever(){
      //   this.dialogRemovePayment= true
      // },
      buttonOke(){
        this.notifSuccess = false
        if (this.notifSuccess == false) this.$router.push({ name: "PaymentMethodList" });
      },
      buttonOkeFailed(){
        this.notifFailed = false
      },
    },
    mounted(){
      this.priority = this.paymentMethodData.priority

      // const payload = {
      //     psgsof_wallet_code: this.$route.params.items.psgsof_wallet_code,
      //     psgsof_wallet_number: this.$route.params.items.psgsof_wallet_number,
      // };

      // this.$store
      //   .dispatch("passenger/getPaymentByCodeNumber", payload)
      //   .then((response) => {
      //     let myData = response.data
      //     this.priority = myData.psgsof_priority
            
      //   })
      //   .catch((err) => {
      //     console.log(err);
      //   });
    }
  }
</script>

<style lang="scss" scoped>
#spacer {
  background: #f5fff7;
  padding: 6px;
}
/* If the screen size is 601px or more, set the font-size of <div> to 80px */
@media only screen and (min-width: 601px) {
  .component-1{
    background-color: #FFFFFF;
    width: 100%;
  }

  .background {
    width: 100%;
    // height: 2%;
    // background-color: #f5fdf7;
    background-color: #000000;
  }
  .label-minus {
    padding-left: -20px;
  }

  .end{
    margin-right: 0px;
  }

  .onHover{
    cursor: pointer;
  }

  .btn-bottom{
    position: fixed;
    bottom:   0;
    width: 95%;
    margin-bottom: 15px;
  }
  .border {
    border-bottom: 1px solid #F5F5F5!important;
  }
}


/* If the screen max size is 600px, set the font-size of <div> to 80px */
@media only screen and (max-width: 600px) {
  .component-1{
    background-color: #FFFFFF;
    width: 100%;
  }

  .background {
    width: 100%;
    height: 2%;
    // margin-top: 2%;
    // background-color: #f5fdf7;
    background-color: #000000;
  }
  .label-minus {
    padding-left: -20px;
  }

  .end{
    margin-right: 0px;
  }

  .onHover{
    cursor: pointer;
  }

  .btn-bottom{
    position: fixed;
    bottom:   0;
    width: 95%;
    margin-bottom: 15px;
  }
}
</style>